
import { useState, useEffect } from "react";

import './FixedTopic.css';

import Logo from 'assets/logo.png';

import { SvgSetaRight } from "components/SvgFile";

import { GetListPag, RegisterListPag } from "interface/InterfacePag";

export default function FixedTopic(){
    
    const [ pageClicked, setPageClicked ] = useState(GetListPag('currentPage'));

    useEffect(()=>{
        RegisterListPag('currentPage', setPageClicked);
    }, []);
    
    return(        
        <div className="ListTopic">
            <div className="logotipo">
                <img alt="logotipo" src={ Logo } className="logo" />
            </div>
            <div className="show_name_menu">
                Dashboard
                { 
                    pageClicked == 'index' ? null :
                    <>
                        <SvgSetaRight color="#2D3C53" className="svg_opt" />
                        { pageClicked }
                    </> 
                }
            </div>
        </div>
    )
}