import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { SetListData } from 'interface/InterfaceData';

const root = ReactDOM.createRoot(document.getElementById('root'));

fetch('./php/list_data.php?acao=list_inf')
    .then(response => {
        return response.json();
    })
    .then(dados => {
        SetListData(dados);
        root.render(
            <React.Fragment>
                <App />
            </React.Fragment>
        ); 
    });