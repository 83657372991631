
import { useState, useEffect } from "react";

import './Login.css';
import Logo from 'assets/logo.png';

import Access from "services/AccessLogin";

export default function Login(){

    const [ email, setEmail ]       = useState('');
    const [ password, setPassword ] = useState('');
    const [ msgError, setMsgError ] = useState('');

    function handleEmail(value){
        setEmail(value);
    }

    function handlePassword(value){
        setPassword(value);
    }

    function saveData(event){
        event.preventDefault();
        Access(email, password, callback_success, callback_error);
    }
    
    function callback_success(){
    }
    
    function callback_error(){
        setMsgError('E-mail ou senha incorreta!');
    }

    return(
        <div className="Login">
            <form onSubmit={ saveData }>
                <div className="content">
                    <div className="div_logo">
                        <img alt="logotipo" src={ Logo } className="logo" />
                    </div>
                    <div className="div_data">
                        E-mail:
                    </div>
                    <div className="div_data">
                        <input type="email" onChange={ (e)=>{ handleEmail(e.target.value) } } value={ email } required placeholder="exemplo@site.com.br" />
                    </div>
                    <div className="div_data">
                        Senha:
                    </div>
                    <div className="div_data">
                        <input type="password" onChange={ (e)=>{ handlePassword(e.target.value) } } value={ password } required placeholder="******" />
                    </div>
                    <div className={ msgError ? "msg_error" : "" }>
                        { msgError }
                    </div>
                    <div className="div_logar">                    
                        <button type="submit" className="button">
                            Entrar
                        </button>
                    </div>
                </div>
            </form>
        </div>
    )
}