
import { useState, useEffect } from "react";

import './MenuLeft.css';

import { GetData } from "interface/InterfaceData";
import { SetListPag, GetListPag, RegisterListPag } from "interface/InterfacePag";

import axios from "axios";

export default function MenuLeft(){
    
    const [ pageClicked, setPageClicked ] = useState(GetListPag('currentPage'));
    const [ listMenu, setListMenu ]       = useState(GetData('menu'));
    const [ stateMenu, setStateMenu ]     = useState(StateMenu());

    useEffect(()=>{
        RegisterListPag('currentPage', setPageClicked);
    }, []);

    function StateMenu(){
        const optMenu = [];
        for (let i = 0; i < listMenu.length; i++) {
            if(listMenu[i] == pageClicked){
                optMenu.push({ "name" : listMenu[i], "state" : true});
            }else {
                optMenu.push({ "name" : listMenu[i], "state" : false});
            }
        }
        return optMenu;
    }

    // click page
    function ClickPage(value, index){
        stateMenu.map((value, index) => {
            stateMenu[index].state = false;
        });
        stateMenu[index].state = true;
        SetListPag('currentPage', value);
    }

    function UploadXml(file){
        const data = new FormData();
        data.append('file', file);

        axios({
            url     : './php/list_data.php?acao=update_file',
            mode    : 'no-cors',
            method  : 'POST',
            data    : data
        })   
        .then(response => {
            console.log(response.data);
        })
    }

    return(
        <div className="ListMenu">
            <div className="list_menu_left">
                <div>                    
                    <div>
                        <label>
                            <div className="menu_name upload_xml">
                                <div>
                                    Enviar arquivo xml
                                </div>
                            </div>
                            <input type="file" onChange={ (e)=>{ UploadXml(e.target.files[0]) } } accept="application/xml"/>
                        </label>
                    </div>
                </div>
                {
                    listMenu.map((key, index) => {                                          
                        return(
                            <div key={ index }>
                                <div>
                                    <div onClick={ ()=>{ ClickPage(key, index) } } className={ stateMenu[index].name == key && stateMenu[index].state == true ? "menu_name active" : "menu_name" }>
                                        <div>
                                            { key }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
                <div>                    
                    <div>                        
                        <a href="./php/pano.xml" download>
                            <div className="menu_name upload_xml">
                                <div>
                                    Download do arquivo xml
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}