
import { useState, useEffect } from "react";

import './ContentList.css';

import { GetData } from "interface/InterfaceData";
import { GetListPag, RegisterListPag } from "interface/InterfacePag";

import TextareaAutosize from 'react-textarea-autosize';

import axios from "axios";

export default function ContentList(){
    
    const [ pageClicked, setPageClicked ] = useState(GetListPag('currentPage'));
    const [ listData, setListData ]       = useState(GetData('machine'));
    const [ text, setText ]               = useState([]);

    useEffect(()=>{
        RegisterListPag('currentPage', setPageClicked);
    }, []);

    function handleText(index, id, value, namePage){    
        const text = [...listData];
        text[index].id    = id;
        text[index].title = value;
        setListData(text);

        setText([...text, {"id" : id, "namePage" : namePage, "title" : value}]);
    }

    function RegisterData(){
        const data = new FormData();

        text.forEach(element => {
            if(element.namePage == pageClicked){
                data.append('id[]', element.id);
                data.append('title[]', element.title);
            }
        });

        axios({
            url     : './php/list_data.php?acao=edit_data',
            mode    : 'no-cors',
            method  : 'POST',
            data    : data
        })   
        .then(response => {
            console.log(response.data);
        })
    }

    return(
        <div className="ContentList">
            {
                text.length > 0 ?
                <div className="register_data" onClick={ ()=>{ RegisterData() } }>
                    <div className="register_text">Registrar alterações</div>
                </div> : null
            }
            {
                listData.map((key, index)=>{
                    if(key.name == pageClicked){
                        return (                        
                            <div key={ index } className="contents">
                                <TextareaAutosize onChange={ (e)=>{ handleText(index, key.id, e.target.value, key.name) } } value={ key.title.replace(/<br>/g,'\n') } />
                            </div>
                        )
                    }
                })
            }
        </div>
    );
}