
import React, { useState, useEffect } from "react";

import Login from "templates/Login";

import { SetListPag, GetListPag, RegisterListPag } from "interface/InterfacePag";

import Cookies from 'universal-cookie';
import Access from "services/AccessLogin";
import Contents from "templates/Contents";

function App() {

    const cookies = new Cookies();  
    const [ currentPage, setCurrentPage ] = useState(GetListPag('page'));

    useEffect(()=>{
        RegisterListPag('page', setCurrentPage);
        if(cookies.get('showroom_vr')){    
            let inf_cookies = cookies.get('showroom_vr').split(';');  
            Access(inf_cookies[0], inf_cookies[1], callback_success, ()=>{});
        }
    }, []);

    // Checked cookies
    function callback_success(){ 
        if(cookies.get('showroom_vr_page')){  
            let cookiesPages = cookies.get('showroom_vr_page'); 
            SetListPag('currentPage', cookiesPages); 
            
        }else {
            cookies.set('showroom_vr_page', 'index', '/', 'komatsushowroom.com.br/');
            let cookiesPages = cookies.get('showroom_vr_page');
            SetListPag('currentPage', cookiesPages); 
        }
    }

    // checked page
    function checkedPage(namePage){
        switch(namePage){
            case "index":
                return(
                    <Contents />
                ); 
            default:
                return(
                    <Login />
                ); 
        }
    }

    return (
        <>
            { checkedPage(currentPage) }
        </>
    );
}

export default App;
