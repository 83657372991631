
import Cookies from 'universal-cookie';
const cookies = new Cookies(); 

let ListPag = {
    "page"        : 'login',
    "currentPage" : 'index'
};

let NotifyListPag = {
    "page"        : [],
    "currentPage" : []
}

export function GetListPag(key){
    return ListPag[key];   
}
export function SetListPag(key, value){
    ListPag[key] = value;
    NotifyListPag[key].forEach(element => {
        element(value);
    });
    
    if(key == 'currentPage'){
        cookies.set('showroom_vr_page', value, '/', 'komatsushowroom.com.br');
    }
}
export function RegisterListPag(key, value){
    if(!NotifyListPag[key]){
        NotifyListPag[key] = []; 
    }
    NotifyListPag[key].push(value);
}